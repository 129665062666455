import { useEffect, useState, createRef } from "react";
import { Button, Col, Container, Row } from "react-bootstrap"
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom"
import { useForgetPasswordMutation } from "../redux-services/apis/authApi";
import { showModel } from "../redux-services/slices/siteDataSlice";
import ShowError from "./Notifications/ShowError";
import ShowModel from "./Notifications/ShowModel";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../config";

export const ForgotPassword = () => {
    const dispatch = useDispatch();
    const recaptchaRef = createRef();
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        email: '',
    });
    const onChange = (event) => {
        setFormData({
            ...formData, [event.target.name]: event.target.value.replace(/[^a-z0-9]/gi, "")
                ? event.target.value.trimLeft()
                : event.target.value.trim()
        });
    };

    const setFocusOn = (fieldId) => {
        const fieldItem = document.querySelector("input[name=" + fieldId + "]");
        fieldItem?.focus();

    }
    const [forgetPassword, forgetPasswordResponse] = useForgetPasswordMutation();

    useEffect(() => {
        if (forgetPasswordResponse.isSuccess && forgetPasswordResponse.data.status) {
            setFormData({ email: "" })
            dispatch(showModel({ isOpen: true, modelType: "success", body: forgetPasswordResponse.data.message }))
        }
        if (forgetPasswordResponse.isError) {
            const errorList = {};
            let firstError = "";
            dispatch(showModel({ isOpen: true, modelType: "danger", body: forgetPasswordResponse.error.data.message }))
            if (forgetPasswordResponse.error?.data?.errors?.length != undefined) {
                forgetPasswordResponse?.error?.data?.errors?.forEach((error, index) => {
                    if (index === 0) {
                        firstError = error.param;
                    }
                    errorList[error.param] = error.msg;
                });

                setErrors(errorList);
                setFocusOn(firstError);
            }
        }
    }, [forgetPasswordResponse.isError, forgetPasswordResponse.isSuccess])

    const onReCAPTCHAChange = async (captchaCode) => {
        // If the reCAPTCHA code is null or undefined indicating that
        // the reCAPTCHA was expired then return early
        if (!captchaCode) {
            return false;
        } else {
            submitHandler(captchaCode)
        }
    }

    const generateCaptach = async (e) => {
        e.preventDefault();
        const tokenData = recaptchaRef.current.execute();
    }

    const submitHandler = async (token) => {
        setErrors({});
        forgetPassword({ email: formData.email, token })
        recaptchaRef.current.reset();
    }
    return (
        <>
            <section className="loginOuter">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="12">
                            <ShowModel />
                        </Col>
                        <Col md="8" lg="6" xl="4">
                            <div className="logoOuter">
                                <Link to="">
                                    <img src="images/logo.svg" />
                                </Link>
                            </div>

                            <div className="loginformOuter">
                                <h4>Forgot Password</h4>
                                <hr />
                                <form onSubmit={generateCaptach} autoComplete="off">
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        size="invisible"
                                        sitekey='6Lfv8oIlAAAAADaw-bW9foGxCPlo0-VPn9Z9tdsN'
                                        onChange={onReCAPTCHAChange}
                                    />
                                    <div className="form-group">
                                        <input type="email" value={formData.email} required name="email" onChange={onChange} placeholder="Enter your email" className="form-control" />
                                        <ShowError addClass={'extra-error'} current_key="email" key="email" errors={errors} />
                                    </div>
                                    <div className="form-group text-center">
                                        <Button type="submit" >Submit</Button>
                                    </div>
                                </form>
                                <div className="account">
                                    <p>Already have an account ? <Link to="/login">Click here to Login.</Link></p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}