import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Row, Col, Button, Breadcrumb, FormText, Modal, ModalBody, ModalHeader, ModalFooter } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { useDoLoginMutation, useDoSubLoginMutation, useSendOtpMutation, useDoRegisterMutation } from "../../redux-services/apis/authApi";
import ShowError from "../Notifications/ShowError";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { RECAPTCHA_SITE_KEY } from '../../config';

const Information = () => {

    const [errors, setErrors] = useState({});
    const recaptchaRef = useRef();
    const [action, setAction] = useState('');

    const [message, setMessage] = useState('');
    const [currPassword, setCurrPassword] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(false);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [signUpFormData, setSignUpFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirm_password: ''
    });

    const [register, registerResponse] = useDoRegisterMutation();

    const onChangeSignUpField = (e) => {
        setSignUpFormData({ ...signUpFormData, [e.target.name]: e.target.value?.trimStart() });
        setErrors({});
    };


    const generateCaptach = async (e) => {
        e.preventDefault();
        setAction(e?.currentTarget?.getAttribute('data-id'))
        const tokenData = recaptchaRef.current.execute();
    }

    const onReCAPTCHAChange = async (captchaCode) => {
        // If the reCAPTCHA code is null or undefined indicating that
        // the reCAPTCHA was expired then return early
        if (!captchaCode) {
            setMessage("Please Select the  Recaptcha")
            return false;
        }
        if (action === "SignUp") {
            onSubmitSignUpForm(captchaCode)
        }
        if (action === 'Login') {
            onSubmit(captchaCode)
        }
    }
    useEffect(() => {

        if (registerResponse.isError) {
            if (registerResponse.error?.data?.errors) {
                let errorsData = {};
                registerResponse.error?.data?.errors.forEach(element => {
                    errorsData[element.param] = element.msg;
                });
                setErrors(errorsData);
            }
        }
    }, [registerResponse]);

    const onSubmitSignUpForm = (token) => {
        const signUpData = {
            first_name: signUpFormData.first_name,
            last_name: signUpFormData.last_name,
            email: signUpFormData.email,
            password: signUpFormData.password,
            token: token,
        }
        if (signUpFormData.password !== signUpFormData.confirm_password) {
            setErrors(err => ({ ...err, confirm_password: "Password do not match" }));
        } else {
            if (!Object.keys(errors).length) {
                register(signUpData);
            }
        }
        recaptchaRef.current.reset();
    };

    const [login, loginResponse] = useDoLoginMutation();
    const [subLogin, subLoginResponse] = useDoSubLoginMutation();
    const [sendOtp, sendOtpResponse] = useSendOtpMutation();
    const [showOtpMode, setShowOtpMode] = useState(false);
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [inputEmail, setInputEmail] = useState("");
    const [selectedOtpOption, setSelectedOtpOption] = useState("email");
    const { email, password } = formData;

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value?.trim() });
    };

    const togglePassword = (e) => {
        e.preventDefault();
        if (e.currentTarget.getAttribute("data-id") == "current_password") {
            setCurrPassword(!currPassword);
            setPasswordShown(passwordShown);
            setConfirmPassword(confirmPassword);
        }
        if (e.currentTarget.getAttribute("data-id") == "password") {
            setPasswordShown(!passwordShown);
            setConfirmPassword(confirmPassword);
            setCurrPassword(currPassword);
        }
        if (e.currentTarget.getAttribute("data-id") == "confirm_password") {
            setPasswordShown(passwordShown);
            setConfirmPassword(!confirmPassword);
            setCurrPassword(currPassword);
        }
    };

    const handleOtpOptionChange = (e) => {
        setSelectedOtpOption(e.target.value);
    }

    const onChangeOtp = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        setOtp(numericValue);
    }

    const onCloseOtpOptions = () => {
        setShowOtpMode(false);
    }

    const onCloseOtpInput = () => {
        setShowOtpInput(false);
    }

    useEffect(() => {
        if (subLoginResponse.isError) {
            setMessage(subLoginResponse.error.data.message);
        } else if (subLoginResponse.isSuccess) {
            if (subLoginResponse.data.response.isDealer) {
                const maskLength = 4;
                const firstMaskedLength = 2;
                const lastMaskLength = 3;
                const domainSeparator = "@";
                const [username, domain] = email.split(domainSeparator);

                let maskedEmail = "";
                if (username.length <= (lastMaskLength * 2)) {
                    // If the username is too short to mask, keep it as is
                    maskedEmail = `${username.charAt(0)}${"*".repeat(username.length - 1)}${domainSeparator}${domain}`;
                } else if (username.length <= firstMaskedLength + (lastMaskLength * 2)) {
                    maskedEmail = `${username.substring(0, 2)}${"*".repeat(username.length - 2)}${domainSeparator}${domain}`;
                } else {
                    const maskedPart = "*".repeat(username.length - (firstMaskedLength + lastMaskLength));
                    maskedEmail = `${username.slice(0, firstMaskedLength)}${maskedPart}${username.substring(username.length - lastMaskLength)}${domainSeparator}${domain}`;
                }

                /* const prefix = email.substring(0, maskLength);
                const maskedPart = "*".repeat(email.length - maskLength - "@yopmail.com".length);
                const maskedEmail = `${prefix}${maskedPart}@yopmail.com`; */
                setInputEmail(maskedEmail);
                if (subLoginResponse.data.response.phone) {
                    const originalPhoneNumber = subLoginResponse.data.response.phone;
                    const countryCode = originalPhoneNumber.substring(0, 3);
                    const lastDigits = originalPhoneNumber.substring(originalPhoneNumber.length - 3);
                    const maskedPhoneNumber = `${countryCode}******${lastDigits}`;
                    setPhoneNumber(maskedPhoneNumber);
                } else {
                    setPhoneNumber(subLoginResponse.data.response.phone);
                }
                setShowOtpMode(true);
            } else if (subLoginResponse.data.response.isAdmin) {
                sendOtp({ email, password, otpMode: selectedOtpOption });
                setShowOtpInput(true);
            }
        }
    }, [subLoginResponse]);

    useEffect(() => {
        if (loginResponse.isError) {
            setMessage(loginResponse.error.data.message);
            if (loginResponse.error.data.errors.otpExprired) {
                setShowOtpInput(false);
                setOtp("");
                setOtpError(true);
                return;
            }
            if (!loginResponse.error.data.errors.otpVerified) {
                setOtpError(true);
                return;
            }
            setShowOtpInput(false);
        } else if (loginResponse.isSuccess) {
            setOtp("");
            setOtpError(false);
        }

    }, [loginResponse]);

    useEffect(() => {
        setOtpError(false);
        if (sendOtpResponse.isError) {
            setMessage(sendOtpResponse.error.data.message);
        } else if (sendOtpResponse.isSuccess) {
            setMessage(sendOtpResponse.data.message);
            setShowOtpMode(false);
            setShowOtpInput(true);
        }
        setTimeout(() => {
            setMessage("");
        }, 10000);
    }, [sendOtpResponse]);

    const onSubmitSendOtp = () => {
        sendOtp({ email, password, otpMode: selectedOtpOption })
    }

    const onSubmitVerifyOtp = () => {
        login({ email, password, otp })
    };

    const onClickResendOtp = () => {
        sendOtp({ email, password, otpMode: selectedOtpOption })
    }

    const onSubmit = (token) => {
        if (token !== '') {
            subLogin({ email, password, token });
        } else {
            setMessage("Please Select the Recaptcha")
        }
        recaptchaRef.current.reset();
    };
    return (
        <>
            <Col md="12" lg="8">
                <div className='breadcrumbOuter'>
                    <Breadcrumb>
                        <Breadcrumb.Item href="#">Cart</Breadcrumb.Item>
                        <Breadcrumb.Item active>Information</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <Row>
                    <Col md="12">
                        <p className="logincontent">Please login or sign up to continue checkout.</p>
                    </Col>
                    <Col md="6" lg="6">
                        <div className='whiteBox orOuter mb-3'>
                            <span className='orcontent'> OR </span>
                            <div className='contacttop'>
                                <div className='w-100'>
                                    <h5 className='mb-2'>Login</h5>
                                    <Form onSubmit={generateCaptach} data-id={"Login"}>
                                        {subLoginResponse.isError ? <div className="alert alert-danger">{message}</div> : null}
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                name="email"
                                                onChange={onChange}
                                                placeholder="Email *"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type={(currPassword) ? "text" : "password"}
                                                name="password"
                                                onChange={onChange}
                                                placeholder="Password *"
                                                className="form-control"
                                            />
                                            <span className='viewpass' onClick={togglePassword} data-id="current_password" >{(currPassword) ? <FaRegEyeSlash /> : <FaRegEye />}</span>
                                        </div>
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            size="invisible"
                                            sitekey='6Lfv8oIlAAAAADaw-bW9foGxCPlo0-VPn9Z9tdsN'
                                            onChange={onReCAPTCHAChange}
                                        />
                                        <div className='form-group mb-0'>
                                            <div className='d-flex justify-content-between align-items-center'>
                                                <Button type="submit">Login</Button>
                                                <Link to="/forgot-password" className='Links'>Forgot Password</Link>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col md="6" lg="6">
                        <div className='whiteBox mb-3'>
                            <div className='contacttop'>
                                <div className='w-100'>
                                    <h5 className='mb-2'>New User</h5>
                                    <Form onSubmit={generateCaptach} data-id={'SignUp'}>

                                        <div className="form-group">
                                            <input
                                                type="text"
                                                value={signUpFormData.first_name}
                                                name="first_name"
                                                onChange={onChangeSignUpField}
                                                min="2"
                                                maxLength="50"
                                                placeholder="First Name*"
                                                className="form-control"
                                                autoComplete="first_name"
                                                required="true"
                                                invalid={errors.first_name ? true : false}
                                            />
                                            <ShowError current_key="first_name" key="first_name" errors={errors} />
                                        </div>

                                        <div className="form-group">
                                            <input
                                                type="text"
                                                value={signUpFormData.last_name}
                                                name="last_name"
                                                maxLength="25"
                                                onChange={onChangeSignUpField}
                                                autoComplete="last_name"
                                                invalid={errors.last_name ? true : false}
                                                placeholder="Last Name"
                                                className="form-control"
                                            />
                                            <ShowError current_key="last_name" key="last_name" errors={errors} />

                                        </div>

                                        <div className="form-group">
                                            <input
                                                type="Email"
                                                value={signUpFormData.email}
                                                name="email"
                                                onChange={onChangeSignUpField}
                                                autoComplete="email"
                                                invalid={errors.email ? true : false}
                                                placeholder="Email*"
                                                required="true"
                                                className="form-control"
                                            />
                                            <ShowError current_key="email" key="email" errors={errors} />

                                        </div>

                                        <div className="form-group">
                                            {signUpFormData.password == '' &&
                                                <Tooltip anchorSelect=".my-anchor-element1" place="bottom">
                                                    Password must contain at least 8 characters, including Upper/lowercase, numbers and special characters
                                                </Tooltip>
                                            }
                                            <input
                                                type={(passwordShown) ? "text" : "password"}
                                                value={signUpFormData.password}
                                                name="password"
                                                onChange={onChangeSignUpField}
                                                autoComplete="password"
                                                invalid={errors.password ? true : false}
                                                placeholder="Password*"
                                                required="true"
                                                className="form-control my-anchor-element1"
                                            />
                                            <span className='viewpass' onClick={togglePassword} data-id="password" >{(passwordShown) ? <FaRegEyeSlash /> : <FaRegEye />}</span>
                                            {/* <ShowError current_key="password" key="password" errors={errors} /> */}
                                        </div>
                                        {errors && errors?.password !== undefined &&
                                            <div id="message" style={{ marginBottom: '12px' }}>
                                                <FormText style={{ fontSize: "10px", marginTop: "-10px" }} className='text-danger'> Password requirements:</FormText>
                                                <FormText>
                                                    <li style={{ fontSize: "10px" }} className='text-danger'>Must contain at least 8 characters</li>
                                                    <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 uppercase letter</li>
                                                    <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 number</li>
                                                    <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 special character (@$!%*#?&.)</li>
                                                </FormText>
                                            </div>
                                        }

                                        <div className="form-group">
                                            {signUpFormData.confirm_password == '' &&
                                                <Tooltip anchorSelect=".my-anchor-element2" place="bottom">
                                                    Password must contain at least 8 characters, including Upper/lowercase, numbers and special characters
                                                </Tooltip>
                                            }
                                            <input
                                                type={(confirmPassword) ? "text" : "password"}
                                                value={signUpFormData.confirm_password}
                                                name="confirm_password"
                                                onChange={onChangeSignUpField}
                                                autoComplete="confirm_password"
                                                invalid={errors.confirm_password ? true : false}
                                                placeholder="Confirm Password*"
                                                required="true"
                                                className="form-control my-anchor-element2"
                                            />
                                            <span className='viewpass' onClick={togglePassword} data-id="confirm_password" >{(confirmPassword) ? <FaRegEyeSlash /> : <FaRegEye />}</span>
                                            <ShowError current_key="confirm_password" key="confirm_password" errors={errors} />

                                        </div>

                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            size="invisible"
                                            sitekey='6Lfv8oIlAAAAADaw-bW9foGxCPlo0-VPn9Z9tdsN'
                                            onChange={onReCAPTCHAChange}
                                        />
                                        <div className='form-group mb-0'>
                                            <Button type="submit">Submit</Button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div >
                    </Col >
                </Row >
            </Col >
            <Modal className='To-factor' show={showOtpMode} centered>
                <div className="logoOuter">
                    <Link to="">
                        <img src="images/logo.svg" />
                    </Link>
                </div>
                <ModalHeader closeButton={showOtpMode} onHide={onCloseOtpOptions} style={{ fontWeight: 600 }}>
                    Two-Factor Authentication
                    <hr />
                </ModalHeader>
                <ModalBody>
                    {phoneNumber ? <p style={{ fontWeight: 500 }}>Choose a Authentication Method</p> : null}
                    <div className='otp-mode-options'>
                        <label className='otp-mode-option'>
                            <input
                                type="radio"
                                value="email"
                                checked={selectedOtpOption === 'email'}
                                onChange={handleOtpOptionChange}
                            />
                            Email - ({inputEmail})
                        </label>
                        {phoneNumber ? <label>
                            <input
                                type="radio"
                                value="phone"
                                checked={selectedOtpOption === 'phone'}
                                onChange={handleOtpOptionChange}
                            />
                            Phone - ({phoneNumber})
                        </label> : null}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div>
                        <Button type="button" onClick={onSubmitSendOtp} style={{ marginRight: "5px" }}>Send OTP</Button>
                        <Button type="button" onClick={onCloseOtpOptions}>Cancel</Button>
                    </div>
                </ModalFooter>
            </Modal>
            <Modal className='To-factor' show={showOtpInput} centered>
                <div className="logoOuter">
                    <Link to="">
                        <img src="images/logo.svg" />
                    </Link>
                </div>
                <ModalHeader closeButton={showOtpInput} onHide={onCloseOtpInput} style={{ fontWeight: 600 }}>
                    Two-Factor Authentication
                    <hr />
                </ModalHeader>
                <ModalBody>
                    <input
                        type="text"
                        value={otp}
                        placeholder='Enter OTP'
                        onChange={(e) => onChangeOtp(e)}
                        className="form-control"
                        style={{ marginBottom: "10px" }}
                    />
                    {(!otpError && sendOtpResponse.isError && message != '') ? <div className="alert alert-danger">{message}</div> : null}
                    {(!otpError && sendOtpResponse.isSuccess && message != '') ? <div className="alert alert-success">{message}</div> : null}
                    {(otpError && loginResponse.isError && message != '') ? <div className="alert alert-danger">{message}</div> : null}
                    {(otpError && loginResponse.isSuccess && message != '') ? <div className="alert alert-success">{message}</div> : null}
                </ModalBody>
                <ModalFooter>
                    <div>
                        <Button type="submit" onClick={onSubmitVerifyOtp} style={{ marginRight: "5px" }} disabled={otp.length <= 3}>Verify OTP</Button>
                        <Button type="button" onClick={onClickResendOtp}>Resend OTP</Button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    );
}

export default Information;
