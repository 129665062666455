import React, { useCallback, useState, useEffect, createRef } from 'react';
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { FiMapPin } from 'react-icons/fi';
import { AiOutlineMail, AiOutlineMobile } from 'react-icons/ai';
import extractNumber from '../utills/extractNumber';
import { getCountryShort } from '../utills/countryCode';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import { HiOutlineMail } from 'react-icons/hi';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useGetDataBySlugQuery } from '../redux-services/apis/technologyApi';
import Spiner from '../components/Layouts/Spiner';
import ReCAPTCHA from "react-google-recaptcha";
import { useAddInquiryMutation } from '../redux-services/apis/inquiryApi';
import ShowError from './Notifications/ShowError';
import { useDispatch } from 'react-redux';
import { showModel } from "../redux-services/slices/siteDataSlice";
import ShowModel from './Notifications/ShowModel.js';

const options = [
    { value: '', label: 'Select the inquiry type *' },
    { value: "Fitter & Builders", label: "Fitter & Builders" },
    { value: "General inquiries", label: "General inquiries" },
    { value: "Media & Marketing inquiries", label: "Media & Marketing inquiries" },
    { value: "Tour department inquiries", label: "Tour department inquiries" },
    { value: 'Other inquiries', label: 'Other inquiries' }
];

const Contact = () => {
    const location = useLocation();
    let dispatch = useDispatch();
    const recaptchaRef = createRef();
    let navigate = useNavigate();
    const [selected, setSelected] = useState(null);
    const [metaDetails, setMetaDetails] = useState({});
    const [addInquiry, addInquiryResponse] = useAddInquiryMutation();
    const [errors, setErrors] = useState({});
    const [validated, setValidated] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const initialState = {
        first_name: '',
        last_name: '',
        email: '',
        ccode: '+1',
        contact_no: '',
        inquiry_type: '',
        iute164_phone: '',
        message: '',
    };
    const [formData, setFormData] = useState(initialState);
    const [defaultCountry, setDefaultCountry] = useState('US');
    const { first_name, last_name, email, ccode, iute164_phone, contact_no, message, inquiry_type } = formData;

    const onChange = useCallback((e) => {
        if (e.target.name == "email") {
            e.target.value = e.target.value.trimStart();
            setFormData({ ...formData, email: e.target.value.trimStart() });
        }
        if (e.target.name == "message") {
            setFormData({ ...formData, message: e.target.value.trimStart() });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value.trim() });
        }
    }, [formData]);

    useEffect(() => {

        const fetchDefaultCountryCode = async () => {
            try {
                const response = await fetch('https://geolocation-db.com/json/');
                const data = await response.json();
                const countryCode = data.country_code || '';

                // Use libphonenumber-js to format the country code
                const phoneNumber = getCountryCallingCode(countryCode);


                if (phoneNumber) {
                    setDefaultCountry(countryCode);
                    setFormData({ ...formData, ccode: `+${phoneNumber}` });

                }
            } catch (error) {
                console.error('Error fetching default country code:', error);
            }
        };

        fetchDefaultCountryCode();

    }, []);

    const onPhoneChange = (number) => {
        const contact_no = extractNumber(number, ccode);
        setFormData((form) => ({
            ...form,
            contact_no: contact_no,
            iute164_phone: number ?? '',
        }));
    };

    const onCountryChange = (code) => {
        setFormData((form) => ({
            ...form,
            ccode: '+' + getCountryCallingCode(code ?? getCountryShort(ccode)),
        }));
    };

    const handleClose = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    const { isLoading, data: getDataBySlug } = useGetDataBySlugQuery({
        slug: location?.pathname.replace('/', '')
    });

    const handleChange = (selectedOption) => {
        setSelected(selectedOption.target.value);
        setFormData({ ...formData, inquiry_type: selectedOption.target.value })
    };

    useEffect(() => {
        setMetaDetails(getDataBySlug?.response[0])
    });

    useEffect(() => {
        if (addInquiryResponse?.data?.errors) {
            if (addInquiryResponse?.data?.errors.length != undefined) {
                let errorsData = {};
                addInquiryResponse?.data?.errors?.forEach((element) => {
                    errorsData[element.param] = element.msg;
                });
                setErrors(errorsData);
            }
            dispatch(showModel({ isOpen: true, modelType: "danger", body: addInquiryResponse?.data?.message }))
        }
        if (addInquiryResponse?.isSuccess && addInquiryResponse?.data?.status) {
            setValidated(false);
            setFormData(initialState);
            setErrors({});
            dispatch(showModel({ isOpen: true, modelType: "success", body: addInquiryResponse?.data?.message }))
        }
    }, [addInquiryResponse]);

    const onReCAPTCHAChange = async (captchaCode) => {
        // If the reCAPTCHA code is null or undefined indicating that
        // the reCAPTCHA was expired then return early
        if (!captchaCode) {
            return false;
        } else {
            onSubmit(captchaCode)
        }
    }

    const generateCaptach = async (e) => {
        e.preventDefault()
        const tokenData = recaptchaRef.current.execute();
    }
    const onSubmit = (token) => {
        addInquiry({ first_name, last_name, email, ccode, contact_no, message, token, inquiry_type });
        setIsOpen(true);
        recaptchaRef.current.reset();
    };

    return (
        isLoading ? (
            <Spiner />
        ) : (
            <>
                {metaDetails && metaDetails?.status == 1 && metaDetails != null ? (
                    <section className='InnerPage'>
                        <div className='headcenter'>
                            <img src={metaDetails && metaDetails?.banner_image} alt='#' />
                            <div className='banner-contentInner'>
                                <h2>
                                    {metaDetails && metaDetails?.page_title}
                                </h2>
                                <h4>{metaDetails && metaDetails?.page_subtitle}</h4>
                            </div>
                        </div>
                    </section>
                ) : (
                    <h2 style={{ textAlign: 'center', margin: '15px' }}>No Data Found</h2>
                )}
                <section className="facility-outer Get-connect">
                    <Container>
                        <Row className='justify-content-between'>
                            <Col md="8" className='m-auto'>
                                <div className='technology-mat-content text-center'>
                                    <h2>Contact Us</h2>
                                    <h4>If you have any questions or concerns, please contact our team as follows:</h4>
                                    <hr />
                                </div>
                                <ShowModel />
                                <div className='getintuch'>
                                    <Form onSubmit={generateCaptach} className="infoform">
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            size="invisible"
                                            sitekey='6Lfv8oIlAAAAADaw-bW9foGxCPlo0-VPn9Z9tdsN'
                                            onChange={onReCAPTCHAChange}
                                        />
                                        <Row>
                                            <Col md="6">
                                                <div className='form-group'>
                                                    <input
                                                        type="text"
                                                        placeholder='First Name *'
                                                        className='form-control'
                                                        name='first_name'
                                                        required
                                                        value={first_name}
                                                        onChange={onChange}
                                                        invalid={errors.first_name ? 'true' : 'false'}
                                                    />
                                                    <ShowError
                                                        current_key="first_name"
                                                        key="first_name"
                                                        errors={errors}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className='form-group'>
                                                    <input
                                                        type="text"
                                                        placeholder='Last Name'
                                                        className='form-control'
                                                        name='last_name'
                                                        value={last_name}
                                                        onChange={onChange}
                                                        invalid={errors.last_name ? 'true' : 'false'}
                                                    />
                                                    <ShowError
                                                        current_key="last_name"
                                                        key="last_name"
                                                        errors={errors}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className='form-group'>
                                                    <input
                                                        type="text"
                                                        placeholder='Email Address *'
                                                        className='form-control'
                                                        name='email'
                                                        required="true"
                                                        value={email}
                                                        onChange={onChange}
                                                        invalid={errors.email ? 'true' : 'false'}
                                                    />
                                                    <ShowError
                                                        current_key="email"
                                                        key="email"
                                                        errors={errors}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="6">
                                                <div className='form-group'>
                                                    <PhoneInput
                                                        className="form-control"
                                                        autoComplete="tel"
                                                        withCountryCallingCode={true}
                                                        countryCallingCodeEditable={false}
                                                        smartCaret={true}
                                                        international={true}
                                                        defaultCountry={defaultCountry}
                                                        value={iute164_phone}
                                                        name="contact_no"
                                                        addInternationalOption={false}
                                                        onChange={onPhoneChange}
                                                        onCountryChange={onCountryChange}
                                                    />
                                                    <ShowError
                                                        current_key="contact_no"
                                                        key="contact_no"
                                                        errors={errors}
                                                    />
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className='form-group'>
                                                    <Form.Select value={formData.inquiry_type} aria-label="Default select example" onChange={handleChange}>
                                                        {options.map(item => (<option value={item.value}>{item.label}</option>))}
                                                    </Form.Select>
                                                    <ShowError
                                                        current_key="inquiry_type"
                                                        key="inquiry_type"
                                                        errors={errors}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className='form-group'>
                                                    <textarea
                                                        placeholder='Your Message *'
                                                        className='form-control'
                                                        name='message'
                                                        required="true"
                                                        value={message}
                                                        onChange={onChange}
                                                        invalid={errors.message ? 'true' : 'false'}
                                                    />
                                                    <ShowError
                                                        current_key="message"
                                                        key="message"
                                                        errors={errors}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md="12">
                                                <div className='form-group mb-0 text-center'>
                                                    <Button type="submit">Send Now</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className='technology-tabs contact-details'>
                    <Container>
                        <Row>
                            <Col md="12">
                                <div className='technology-mat-content text-center mb-lg-5'>
                                    <h2>{metaDetails?.support_title ? metaDetails?.support_title : ""}</h2>
                                    <h4>{metaDetails?.support_subtitle ? metaDetails?.support_subtitle : ""}</h4>
                                    <hr />
                                </div>
                                <div className='inquiries-list'>
                                    <ul>
                                        {metaDetails && metaDetails?.supportData?.length > 0 &&
                                            metaDetails?.supportData?.map(item => (
                                                <li>
                                                    <div>
                                                        <center><HiOutlineMail /></center>
                                                        <span>{item?.title ?? ""}</span>
                                                        <a href={`mailto:?subject=${item?.email ?? ''}`}>{item?.email ?? ""}</a>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                        {/* <li>
                                            <div>
                                                <center><HiOutlineMail /></center>
                                                <span>Customer service and consumer sales, product fulfillment and support inquiries:</span>
                                                <a href={`mailto:?subject=padersoncompositeusa@gmail.com`}>padersoncompositeusa@gmail.com</a>
                                            </div>
                                        </li>
                                        <li><div>
                                            <center><HiOutlineMail /></center>
                                            <span>Dealer/Fitter, OEM/ODM sales and support inquiries:</span>
                                            <a href={`mailto:?subject=sales@padersonshafts.com`} target="_blank">sales@padersonshafts.com</a></div>
                                        </li>
                                        <li><div>
                                            <center><HiOutlineMail /></center>
                                            <span>International sales and distribution inquiries:</span>
                                            <a href={`mailto:?subject=globalSales@padersonshafts.com`} target="_blank">globalSales@padersonshafts.com</a></div>
                                        </li>
                                        <li><div>
                                            <center><HiOutlineMail /></center>
                                            <span>Tour, fit and technical inquiries:</span>
                                            <a href={`mailto:?subject=kinetixxgearhead@padersonshafts.com`} target="_blank">kinetixxgearhead@padersonshafts.com</a></div>
                                        </li>
                                        <li><div>
                                            <center><HiOutlineMail /></center>
                                            <span>Paderson FRP composite sales division:</span>
                                            <a href={`mailto:?subject=FRPCompositeSales@padersonshafts.com`} target="_blank">FRPCompositeSales@padersonshafts.com</a></div>
                                        </li> */}
                                    </ul>
                                </div>

                            </Col>

                        </Row>
                    </Container>
                </section>

                <section className='company-address facility-outer'>
                    <Container fluid>
                        <Row>
                            <Col md="12">
                                <div className='technology-mat-content text-center mb-lg-5'>
                                    <h2>Our Headquarters</h2>
                                    <hr />
                                </div>
                            </Col>
                            <Col md="6" className='ps-md-0'>
                                <div className='headOuter'>
                                    <div className='officeaddress'>
                                        <address>
                                            <strong>USA:</strong>
                                            <ul className='infolistdetails'>
                                                <li style={{ fontWeight: "bold" }}>KINETIXX Technology Inc</li>
                                                <li>(Global R&D, Performance Test Center, Sales, Distribution, Corporate & Finance HQ)</li>
                                                <li>
                                                    <FiMapPin /> 1060 Calle Negocio{' '}
                                                    <span>Suite E, </span>
                                                    <span>San Clemente, CA, 92673</span>
                                                </li>
                                                <li>
                                                    <AiOutlineMobile /> <a href="tel:949-441-0154">949-441-0154</a>
                                                </li>
                                                <li>(Not a retail outlet)</li>
                                            </ul>
                                        </address>
                                    </div>
                                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3328.7873147825067!2d-117.60272324927324!3d33.45484725632714!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcf39cee9271a7%3A0xa5302870eb57c4bd!2s1060%20Calle%20Negocio%2C%20San%20Clemente%2C%20CA%2092673%2C%20USA!5e0!3m2!1sen!2sin!4v1676292186410!5m2!1sen!2sin" width="600" height="350" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6657.574973752671!2d-117.6056790449333!3d33.45484277338774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcf315120bc9d7%3A0xd99632462817f03f!2sKINETIXX%20TECHNOLOGY%20Inc.!5e0!3m2!1sen!2sin!4v1691645523679!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </Col>
                            <Col md="6" className='pe-md-0'>
                                <div className='headOuter mb-0'>
                                    <div className='officeaddress'>
                                        <address>
                                            <strong>TAIWAN:</strong>
                                            <ul className='infolistdetails'>
                                                <li style={{ fontWeight: "bold" }}>KINETIXX Technology Inc</li>
                                                <li>Composite Fiber Tech Co., Ltd.</li>
                                                <li>(Global Factory Operations)</li>
                                                <li>
                                                    <FiMapPin /> No. 138, Yichang E. Rd., Taiping Dist.,{' '}
                                                    <span>Taichung City 41163, Taiwan R.O.C.</span>
                                                </li>
                                                <li>
                                                    <AiOutlineMobile /> <a href="tel:886-4-2355-5698">886-4-2355-5698</a>
                                                </li>
                                                <li>(Not a retail outlet)</li>
                                            </ul>
                                        </address>
                                    </div>
                                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14560.104568512992!2d120.58123169723854!3d24.17081593646914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34693e19dae11089%3A0xd6b55659c4b18ab7!2zNDA3LCBUYWl3YW4sIFRhaWNodW5nIENpdHksIFhpdHVuIERpc3RyaWN0LCDlj7DkuK3lt6Xmpa3ljYA!5e0!3m2!1sen!2sin!4v1691666171589!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1299.2011692822425!2d120.7188577240048!3d24.143254876580123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346922b1eab0445d%3A0x10b6b8d87e5c99fc!2sNo.%20138%2C%20Yichang%20E%20Rd%2C%20Taiping%20District%2C%20Taichung%20City%2C%20Taiwan%20411!5e0!3m2!1sen!2sin!4v1714025395081!5m2!1sen!2sin%22" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

            </>

        )
    );
}

export default Contact;
